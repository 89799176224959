export const text_ru = {
    "INBOX": "Входящие",
    "trash": "Корзина",
    "sent": "Отправленные",
    "junk": "Спам",
    "draft": "Черновики",
    "labels": "Ярлыки",
    "new_msg": "Новое сообщение",
    "new_msg2": "Написать письмо",
    "from": "От кого:",
    "from1": "От кого",
    "from2": "От:",
    "to": "Кому:",
    "to1": "Кому",
    "more": "Подробнее",
    "translate": "Перевести",
    "original": "Оригинал",
    "forward": "Переслать",
    "reply": "Ответить",
    "reply_to_all": "Ответить всем",
    "previous_page": "Предыдущая страница",
    "next_page": "Следующая страница",
    about: "О сервисе",
    files: "Файлы",
    memo: "Мемо",
    notes: "Заметки",
    video: "Видео",
    "contacts": "Контакты",
    "settings": "Настройки",
    "mails_total": "Всего писем",
    "refresh": "Обновить",
    "add_to_folder": "Переместить в папку",
    "add_to_spam": "Добавить в спам",
    "add_label": "Добавить ярлык",
    "create_label": "Создать ярлык",
    "delete": "Удалить",
    "remove_all": "Удалить всё",
    "mark_as_read": "Отметить как прочитанное",
    "mark_as_unread": "Отметить как непрочитанное",
    "mail_search": "Поиск писем",
    "mark_as_important": "Отметить как важное",
    "mark_as_unimportant": "Убрать из избранного",
    "more_options": "Еще",
    "all": "Все",
    "read": "Прочитанные",
    "unread": "Непрочитанные",
    "marked": "Помеченные",
    "unmarked": "Без пометок",
    "additional": "Доп.:",
    "collapse": "Свернуть",
    "in_full_screen": "На весь экран",
    "close": "Закрыть",
    "copy": "Копия",
    "hidden_copy": "Скрытая копия",
    "whom_to_answer": "Кому ответить",
    "add_attachments": "Добавить вложения",
    "additional_options": "Дополнительные опции",
    "subject": "Тема:",
    "subject1": "Тема",
    "subject2": "Тема:",
    "send": "Отправить",
    "date": "Дата:",
    "original_message": "Исходное сообщение",
    "login": "Вход",
    "register": "Регистрация",
    "username": "Логин",
    "password": "Пароль",
    "remember_me": "Запомнить меня",
    "enter": "Войти",
    "as_wmt_user": "Использовать аккаунт WebMoney",
    "attention": "Внимание!",
    "enter_login": "Введите Логин",
    "enter_password": "Введите пароль",
    "enter_email": "Введите корректный email!",
    "wrote": "писал",
    "all_messages": "Все сообщения",
    "return_to_mailslist": "Перейти ко входящим",
    "never": "Никогда",
    "always": "Всегда",
    "correspondent": "От контактов",
    "new_message": "В новых сообщениях",
    "response": "В ответах",
    "filters": "Фильтры",
    "folders": "Папки",
    "main": "Основные",
    "profiles": "Профили",
    "aliases": "Псевдонимы",
    "add_contact": "Добавить контакт",
    "total": "Всего",
    "write": "Написать",
    "add_folder": "Добавить папку",
    "select_mails": "Выделить все письма",
    "search_mails": "Поиск",
    "search_mails2": "Найти",
    "new_folder": "Новая папка",
    "create": "Создать",
    "folder_name": "Имя папки",
    "folder_location": "Расположение папки",
    "enter_folder_name": "Введите название папки",
    "enter_request": "Введите запрос",
    "additional_search": "Расширенный поиск",
    "alias": "Псевдоним",
    "clear": "Очистить",
    "sender": "Отправитель",
    "recipient": "Получатель",
    "name_or_email": "Имя или адрес эл. почты",
    "date_from": "Дата начала",
    "date_to": "Дата окончания",
    "alias_price": " Сумма платежа: ",
    "list_aliases": "Список псевдонимов",
    "list_aliases2": "Ваши псевдонимы",
    "list_aliases3": "Служебные псевдонимы",
    "unpaid_aliases": "Неоплаченные псевдонимы",
    "get_passport1": "Для создания псевдонима необходим аттестат не ниже формального.",
    "get_passport2": "Для создания домена необходим аттестат не ниже формального.",
    "available_aliases": "Псевдонимов для создания доступно:",
    "alias_name": "Название псевдонима",
    "create_alias": "Создание псевдонима",
    "create_paid_alias": "Создание платного псевдонима",
    "add_new_alias": "Добавить новый псевдоним",
    "add_new_paid_alias": "Добавить платный псевдоним",
    "aliases_available": "У вас создано максимальное количество бесплатных псевдонимов. Вы можете создать новый за ",
    "new_aliases_available": "Вы уже создали максимальное количество бесплатных псевдонимов! {br} Создание дополнительного псевдонима платное. {br_mobile} Цена {price}. {br} После указания имени псевдонима Вам будет прислан счет, который необходимо оплатить.",
    "groups": "Группы",
    "group": "Группа",
    "security": "Безопасность",
    "of": "из",
    "used": "Использовано",
    "enter2": "Введите",
    "enter_word": "Введите слово",
    "enter_wmid": "Введите wmid",
    "msg_sent": "Сообщение успешно отправлено!",
    "letters_selected": "Выбрано писем: ",
    "select_all": " Выбрать все письма",
    "in_the_folder": "в папке ",
    "clear_selection": "Снять выделение",
    "delegate": "Делегировать",
    "delegation": "Делегирование",
    "for_delegation": "для делегирования",
    "delegated": "Делегирован",
    "delegating": "Делегированные emails",
    indicate_delegate_wmid: "Для делегирования email {email} укажите wmid нового владельца:",
    "confirm_d_email_success": "Email успешно добавлен!",
    "try_confirm_d_email": "Запрос на делегирование email отправлен.",
    "my_ba": "Мои БА",
    "client_settings": "Параметры настройки для SMTP, IMAP, POP3 клиента",
    "smtp": "Для настройки SMTP-клиента используйте следующие параметры:",
    "or": "или",
    "imap": "Для настройки IMAP-клиента используйте следующие параметры:",
    "pop3": "Для настройки POP3-клиента используйте следующие параметры:",
    "see_link": "Подробнее см. по ссылке ",
    "wm_mail": "https://wiki.webmoney.ru/projects/webmoney/wiki/wm_pochta",
    "attempts": "Попытки авторизации",
    "ip": "IP-адрес",
    "date2": "Дата",
    "status": "Статус",
    "comment": "Комментарий",
    "access_control": "Контроль доступа",
    "create_passw": "Создать пароль автоматически",
    "set_psw": "Задать пароль вручную",
    "code_sent_wm": "Вам был выслан код подтверждения в WM-кипер. Укажите его в данном поле:",
    "new_psw": "Новый пароль",
    "again": "еще раз",
    "enter_code": "Введите код",
    "enter_new_psw": "Введите новый пароль",
    "reenter_new_psw": "Введите новый пароль повторно",
    "code_sent_wm2": "Код подтверждения отправлен в WM-кипер.",
    "changes_saved": "Изменения успешно сохранены!",
    "display_mails": "Отображение писем",
    "show_chain": "Отображать цепочки писем",
    "letters_per_page": "Писем на странице",
    "show_pictures": "Отображать картинки в письме",
    "signature_setup": "Настройка подписи",
    "signature_response": "Подпись при ответе",
    "add_signature": "Добавлять подпись",
    "notifications": "Уведомления",
    "notify_in_wm": "Оповещать в WebMoney Keeper",
    "add_new_profile": "Добавить новый профиль",
    "edit": "Редактировать",
    "editing_profile": "Редактирование профиля",
    "creation_profile": "Создание профиля",
    "display_name": "Отображаемое имя",
    "enter_display_name": "Введите отображаемое имя",
    "organization": "Организация",
    "specify_organization": "Укажите организацию",
    "enter_value2": "Введите значение",
    "use_default_profile": "Использовать профиль по умолчанию",
    "signature_options": "Параметры подписи",
    "deleting_profile": "Удаление профиля",
    "are_you_sure1": "Вы уверены, что хотите удалить профиль для ",
    "yes": "Да",
    "profile_saved": "Профиль успешно сохранен!",
    "invalid_email": "Неверно указан email для ",
    "hidden_copy2": "скрытой копии",
    "psw_match": "Пароли должны совпадать!",
    "psw_changed": "Пароль успешно изменен.",
    "psw_sent": "Пароль отправлен Вам в WM-кипер.",
    "label_creation": "Создание ярлыка",
    "label_editing": "Редактирование ярлыка",
    "label_name": "Название ярлыка",
    "label_color": "Цвет ярлыка",
    "search_field": "Поле поиска",
    "search_folder": "Папка поиска",
    "search_query": "Поисковой запрос",
    "enter_what_find": "Введите, что нужно найти",
    "add_new_label": "Добавить новый ярлык",
    "label_removing": "Удаление ярлыка",
    "are_you_sure2": "Вы уверены, что хотите удалить ярлык ",
    "folder_name2": "Название папки",
    "folder_creation": "Создание папки",
    "folder_editing": "Редактирование папки",
    "create_new_folder": "Создать новую папку",
    "folder_removing": "Удаление папки",
    "are_you_sure3": "Вы уверены, что хотите удалить папку ",
    "folder_saved": "Папка успешно сохранена!",
    "letter_body": "Тело письма",
    "add_new_filter": "Добавить новый фильтр",
    "filter_editing": "Редактирование фильтра",
    "filter_creating": "Создание фильтра",
    "filter_name": "Название фильтра",
    "for_incoming_mail": "Для входящей почты ",
    "complies_with_rules": "Соответствует всем указанным правилам",
    "matches_rules": "Соответствует любому из указанных правил",
    "perform_actions": "Выполнить следующие действия",
    "disable_filter": "Отключить фильтр",
    "filter_removing": "Удаление фильтра",
    "are_you_sure4": "Вы уверены, что хотите удалить фильтр ",
    "filter_saved": "Фильтр успешно сохранен!",
    "add": "Добавить",
    "remove": "Убрать",
    "was_read": "Прочитано",
    "tagged": "Помечено",
    "enter_value_field_above": "Введите значение поля выше",
    "has": "содержит",
    "has_not": "не содержит",
    "is": "соответствует",
    "is_not": "не соответствует",
    "choose_action": "Выберите действие",
    "fileinto": "Переместить сообщение в",
    "fileinto_copy": "Скопировать сообщение в",
    "redirect": "Перенаправить сообщение на",
    "redirect_copy": "Отправить копию сообщения на",
    "setflag": "Установить флаги на сообщение",
    "discard": "Удалить сообщение",
    "stop": "Закончить выполнение",
    "group_name": "Название группы",
    "contacts_group": "Контакты в группе",
    "group_editing": "Редактирование группы",
    "group_creation": "Создание группы",
    "contact_address": "Введите адреса контактов",
    "contact_groups": "Группы контактов",
    "new_group": "Создать новую группу",
    "group_removing": "Удаление группы",
    "are_you_sure5": "Вы уверены, что хотите удалить группу ",
    "group_saved": "Группа успешно сохранена.",
    "name": "Имя",
    "contact_name": "Введите имя",
    "surname": "Фамилия",
    "enter_last_name": "Введите фамилию",
    "contact_editing": "Редактирование контакта",
    "contact_adding": "Добавление контакта",
    "enter_email1": "Введите email",
    "phone_number": "Номер телефона",
    "enter_phone_number": "Введите номер телефона",
    "date_of_birth": "Дата рождения",
    "enter_date_birth": "Укажите дату рождения",
    "add_to_group": "Добавить в группу",
    "contact_information": "Информация о контакте",
    "are_you_sure6": "Вы уверены, что хотите удалить контакт ",
    "contact_removing": "Удаление контакта",
    "login_to_mailbox": "Войти в почтовый ящик",
    "message_decryption": "Расшифровка сообщения",
    "paste_message": "Вставьте сообщение",
    "decrypt": "Расшифровать",
    "message_decrypted": "Сообщение расшифровано",
    "logout": "Выход",
    "login_another_wmid": "Войти под другим WMID",
    "encrypt": "Зашифровать",
    "text": "Текст",
    "important": "Важные",
    "contains": "Содержит",
    "search_in_all_folders": "Искать во всех папках",
    "search_important": "Искать важные в текущей папке",
    "search_in_folder": "Искать в текущей папке",
    "mails_removing": "Удаление писем",
    "are_you_sure7": "Вы уверены, что хотите удалить все письма в",
    "folder": "папке",
    "label": "ярлыке",
    "where_to_look": "Где искать",
    "contact_saved": "Контакт успешно сохранен.",
    "current_msg": "Текущее письмо",
    "current_page": "Текущая страница",
    "chain_messages": "Цепочка писем",
    "encrypted_message": "Зашифрованное письмо",
    "there_are_attachments": "Есть вложения",
    "not_indicated": "Не указано",
    "download_original": "Скачать оригинал",
    "show_images": "Показать изображения",
    "msg_is_encrypted": "Письмо зашифровано.",
    "enter_msg_psw": "Введите пароль от письма",
    "incorrect_code": "Некорректный код",
    "enter_psw_sender": "Введите пароль, который вам передал отправитель",
    "download": "Скачать",
    "invalid_email_for": "Неверно указан email для ",
    "recipient2": "получателя",
    "cc": "копии",
    "bcc": "скрытой копии",
    "reply_to": "кому ответить",
    "no_messages_here": "Здесь пока нет писем",
    "are_you_sure8": "Вы уверены, что хотите удалить также цепочку писем?",
    "domain_text26": "Добавьте новую TXT запись с параметрами",
    "data_processing": "Данные в процессе обработки",
    "removing_email_address": "Удаление email адреса",
    "are_you_sure9": "Вы уверены, что хотите удалить email ",
    "show_msg_preview": "Показывать превью письма",
    "show_slim_template": "Показывать компактный интерфейс",
    "show_field_to": "Показывать email получателя",
    "set_password_access": "Доступ с паролем",
    "password_access": "Доступ",
    "keeper_notification_duration": "Частота уведомлений в wm keeper (в минутах)",
    "save_changes": "Сохранить изменения",
    "minutes": "минут",
    "copy_smth": "Скопировать",
    "email_address": "Email-адрес",
    "are_you_sure_with_param": "Вы уверены, что хотите удалить {msg}? ",
    "you_wont_receive_msg": "Вы не сможете получать сообщения на данный емейл.",
    "psw_change": "Изменение пароля",
    "ok": "OK",
    "are_your_sure_change_psw": "Вы уверены, что хотите сменить пароль? Новый пароль будет выслан вам в WM-keeper.",
    "in_search": " заданном поиске",
    "show_slim_list_aliases": "Компактный вид списка псевдонимов",
    "remove_from_spam": "Убрать из спама",
    "confirm_d_emails": "Подтвердить",
    "copied": "Скопировано",
    "no_d_emails": "У вас нет делегированных email-адресов.",
    set_alias_psw: "Задать пароль",
    setAliasPswTitle: "Установка пароля для email",
    areYouSureSetPsw: "Вы уверены, что хотите задать пароль для {msg}?",
    resetPsw: "Сменить пароль",
    resetAliasPswTitle: "Изменение пароля",
    oldPswWillnoValid: " Старый пароль будет недействителен.",
    deleteAlias: "Удалить email",
    deleteAliasAccess: "Удалить пароль",
    areYouSureRemovePsw: "Вы уверены, что хотите удалить пароль для доступа к email {msg}?",
    psw_deleted: "Пароль успешно удален.",
    psw_set: "Задан пароль",
    description: "WebMoney Mail — почтовый сервис с удобным интерфейсом для участников системы WebMoney, обеспечивающий эффективное взаимодействие.",
    collectors: "Сборщик почты",
    collectors_title: "Почта с других ящиков",
    collectots_subtitle: "Собирайте всю почту в одном месте",
    collectors_content: "Сборщик автоматически переносит старые и новые письма из других почтовых ящиков,  включая заблокированные в РФ, в Webmoney Почту. Вы можете настроить сохранение копий писем в исходных ящиках или отключить эту опцию.",
    collectors_settings: "Настройте сбор писем с любого сервера, работающего по протоколу IMAP.",
    another_email: "Другая почта",
    collector_indicate_email_data: "Укажите email с которого хотите собирать письма",
    collector_indicate_email: "Email",
    collector_indicate_psw: "Пароль",
    collector_cancel: "Отмена",
    add_email_address: "Подключить email-адрес",
    host: "host",
    port: "port",
    next: "Далее",
    collector_confirm_email: "Подтвердите email",
    collector_enter_data_by_hand: "Ввести данные вручную",
    collectots_connected: "Подключенные email-адреса",
    deleting_collector: "Отключение сборки писем",
    are_you_sure_collector: "Вы уверены, что хотите отключить сборку писем для email {email}?",
    collector_checkbox_remove: "Удалять письма из источника",
    auth_with_oauth: "Авторизоваться в аккаунте {provider}",
    back: "Назад",
    disable: "Отключить",
    enable: "Включить",
    collectots_disconnected: "Отключенные email-адреса",
    updated: "Обновлено: ",
    collector_imap_connection: "IMAP подключение",
    collectots_confirm_hint: "Ознакомьтесь с документацией по подключению этого сервиса: ",
    show_unread_first: "Показывать сначала непрочитанные",
    terms: "Условия",
    terms_url: "https://ru.webmoney.wiki/projects/webmoney/wiki/usloviya_ispolzovaniya_pochtovogo_servisa_webmoney_mail",
    private_policy: "Политика",
    private_policy_url: "https://ru.webmoney.wiki/projects/webmoney/wiki/politika_konfidentsialnosti_servisa_webmoney_mail",
    aboutPage: {
        header: {
            title: "WebMoney Mail",
            paragraph: "Защищенная электронная почта с защитой от спама для участников системы WebMoney Transfer",
            linkText: "Перейти в Почту"
        },
        main: {
            work: {
                title: "WebMoney Mail для работы",
                section1: {
                    grid1: {
                        title: "Почта со своим доменом",
                        paragraph: "Создайте почтовый ящик с адресом вашего домена для профессиональной переписки и укрепления доверия к вашему бренду"
                    },
                    grid2: {
                        title: "Выдача доступа к почте",
                        paragraph: "Делегируйте доступ к отдельным ящикам, чтобы ваши сотрудники или коллеги могли работать с почтой"
                    },
                    grid3: {
                        title: "Интеграция с WebMoney",
                        paragraph: "Настраивайте интерфейс почты под свои задачи и пользуйтесь преимуществами интеграции с другими сервисами WebMoney"
                    }
                },
                section2: {
                    grid1: {
                        title: "Одна почта - неограниченное количество псевдонимов",
                        paragraph: "Используйте уникальные псевдонимы для разных ситуаций. Вы можете создать один бесплатный и неограниченное количество платных псевдонимов и получать на них письма в одном месте"
                    },
                    grid2: {
                        title: "Получайте и отправляйте защифрованные письма",
                        paragraph: "WebMoney Mail позволяет шифровать письма и защищать их паролем, чтобы доступ к содержимому был только у тех, кому вы доверяете"
                    },
                    grid3: {
                        title: "Читайте все письма в одном месте",
                        paragraph: "С помощью автоматического сборщика писем вы можете перенаправлять почту с других почтовых ящиков в вашу почту WebMoney Mail"
                    }
                }
            },
            security: {
                title: "Ваша переписка в безопасности",
                list: {
                    paragraph1: "Информация хранится в безопасном репозитории и защищается шифрованием во время передачи",
                    paragraph2: "Автоматическая система проверки на спам и вирусы не пропустит письма от мошенников. Проверка проходит автоматически",
                    paragraph3: "Сервис WebMoney Mail защищен надежной системой авторизации WebMoney Login с двухфакторной аутентификацией",
                }
            },
            other: {
                title: "Другие сервисы WebMoney для работы",
                grid1: {
                    title: "WebMoney Files",
                    paragraph: "Сервис для защищенного хранения и обмена файлами. Доступно 40 Гб"
                },
                grid2: {
                    title: "Memo",
                    paragraph: "Хранилище ссылок для структурирования ресурсов"
                },
                grid3: {
                    title: "Notes",
                    paragraph: "Бесплатный сервис для оформления и публикации заметок"
                },
                linkText: "Подробнее"
            },
            faq: {
                title: "Часто задаваемые вопросы",
                question1: {
                    title: "Как перенести письма с других ящиков в WebMoney Mail?",
                    linkText: "Настройте",
                    paragraph: "автоматический сборщик писем с вашего старого ящика, работающего по протоколу IMAP, чтобы перенести старую переписку и автоматически получать новые сообщения на WebMoney Mail. Для почты на собственном домене вы можете перенести домен на серверы WebMoney"
                },
                question2: {
                    title: "Какой объем памяти доступен в WebMoney Mail?",
                    paragraph: "Каждому пользователю доступно до 1 Гб пространства для писем. А также для участников системы WebMoney доступно до 40 Гб в сервисе",
                    linkText: "WebMoney Files",
                },
                question3: {
                    title: "Сколько псевдонимов можно создать?",
                    paragraph: {
                        part1: "Каждому пользователю предоставляется несколько стандартных псевдонимов в формате",
                        highlightedText1: "вашwmid{'@'}wmid.com",
                        part2: "и",
                        highlightedText2: "вашwmid{'@'}wmkeeper.com.",
                        part3: "Также доступна возможность создать один бесплатный псевдоним с доменами",
                        highlightedText3: "{'@'}wmid.net, {'@'}wmid.com, {'@'}wmids.com",
                        part4: "или",
                        highlightedText4: "{'@'}wmail.ru.",
                        part5: "Кроме того, вы можете создать неограниченное количество платных псевдонимов по цене 1 WMZ за каждый. Если вам нужен профессиональный почтовый адрес с собственным доменом, вы можете перенести его на серверы WebMoney",
                    }
                },
                question4: {
                    title: "Какого размера файлы можно прикреплять к письму?",
                    paragraph: "Вы можете прикрепить неограниченное количество файлов любого формата общим объемом до 50 Мб"
                },
                question5: {
                    title: "Как подключить свой домен к почте WebMoney?",
                    paragraph: "Перейдите в настройки почты и откройте раздел “Мои домены”. Добавьте свой домен и подтвердите права на него. После этого вы сможете использовать свой домен для создания почтовых адресов в формате",
                    highlightedText: "адрес_вашей_почты{'@'}имя_вашего_домена.com"
                }
            }
        }
    },
    support: "Поддержка",
    enter_wmid_or_alias: "Укажите WMID или email:",
    enter_val: "Введите значение",
    search: "Найти",
    invalid_email_or_wmid: "Неверно указан email или wmid!",
    mail_service: "Почта"
}
