<template>
  <div class="top-panel"
    :class="{'top-panel--mail': isMobile && mailIsOpen}">
    <ul class="top-panel__list top-panel__list--left">
      <mails-list v-if="!hideMailsList"
      ref="mails_list"
      :email="email"
      :hasChecked="hasChecked"
      :subfolders="subfolders"
      :isLoading="isLoading"
      :labels="labels"
      :lang="lang"
      :isMobile="isMobile"
      :isCheckedALL="isCheckedALL"
      :activeEmail='activeEmail'
      :amount="amount"
      :counter_click_folder="counter_click_folder"
      :isCheckedAllFolder="isCheckedAllFolder"
      @add_label="add_label"
      @refresh="refresh"
      @remove_all="remove_all"
      @move_to="move_to"
      @mark_as_read="mark_as_read"
      @select_mails="selectMails"
      @search_mail="search_mail"
      @delete_mail="delete_mail"></mails-list>
    </ul>
    <ul class="top-panel__list top-panel__list--right" >
      <li class="top-panel__item top-panel__item--pages" v-if="total > 0 && !hideMailsList"><span class="top-panel__pages top-panel__pages--current" data-bs-toggle="tooltip" :title="$t('current_page')">{{letters}}</span>из<span class="top-panel__pages top-panel__pages--all" data-bs-toggle="tooltip" :title="$t('mails_total')">{{total}}</span></li>
      <li v-if="!hideMailsList" class="top-panel__item" :class="{'top-panel__item--disabled': page === 1}" data-bs-toggle="tooltip" :title="$t('previous_page')">
        <button class="top-panel__button" @click="previous">
          <SvgSprite class="icon" symbol="arrow-left" size="32"/>
        </button>
      </li>
      <li v-if="!hideMailsList" class="top-panel__item" :class="{'top-panel__item--disabled': page === pages_amount || total === 0}" data-bs-toggle="tooltip" :title="$t('next_page')">
        <button class="top-panel__button" @click="next">
          <SvgSprite class="icon" symbol="arrow-right" size="32"/>
        </button>
      </li>
      <li class="top-panel__item top-panel__item--pages" v-if="total > 0 && hideMailsList"><span class="top-panel__pages top-panel__pages--current" data-bs-toggle="tooltip" :title="$t('current_msg')">{{indexMail}}</span>из<span class="top-panel__pages top-panel__pages--all" data-bs-toggle="tooltip" :title="$t('mails_total')">{{total}}</span></li>
      <li v-if="hideMailsList" class="top-panel__item" :class="{'top-panel__item--disabled': indexMail === 1}" data-bs-toggle="tooltip" :title="$t('previous_page')">
        <button class="top-panel__button" @click="previousLetter">
          <SvgSprite class="icon" symbol="arrow-left" size="32"/>
        </button>
      </li>
      <li v-if="hideMailsList" class="top-panel__item" :class="{'top-panel__item--disabled': indexMail === total}" data-bs-toggle="tooltip" :title="$t('next_page')">
        <button class="top-panel__button arrow-right" @click="nextLetter">
          <SvgSprite class="icon" symbol="arrow-right" size="32"/>
        </button>
      </li>
      <li class="top-panel__item top-panel__item--links"  v-if="!userAlias">
        <button class="top-panel__button top-panel__button--toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <SvgSprite class="icon" symbol="dots" size="32"/>
        </button>
        <ul class="top-panel__dropdown-menu dropdown-menu">
          <li v-if="isMobile" class="top-panel__dropdown-item" data-bs-toggle="tooltip" :title="$t('about')" >
            <a class="top-panel__link" @click="about">
              <SvgSprite class="icon" symbol="info-rounded" size="24" />
              {{ $t('about') }}
            </a>
          </li>
          <li class="top-panel__dropdown-item" data-bs-toggle="tooltip" :title="$t('contacts')" >
            <a class="top-panel__link" @click="contacts">
              <SvgSprite class="icon" symbol="contacts" :size="isMobile ? '24' : '32'" />
              {{ $t('contacts') }}
            </a>
          </li>
          <li class="top-panel__dropdown-item" data-bs-toggle="tooltip" :title="$t('settings')">
            <a class="top-panel__link" @click="settings">
              <SvgSprite class="icon" symbol="settings" :size="isMobile ? '24' : '32'" />
              {{ $t('settings') }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import MailsList from './MailsList.vue';
export default {
  name: "HeaderComponent",
  emits: ['delete_mail', 'previous', 'next', 'search_mail', 'refresh', 'mark_as_read', 'return_to_mailslist', 'move_to', 'select_mails', 'previous_letter', 'next_letter', 'add_label'],
  components: {
    MailsList
  },
  props: {
    page: Number,
    pages_amount: Number,
    amount: Number,
    total: Number,
    hideMailsList: Boolean,
    email: String,
    hasChecked: Boolean,
    subfolders: Array,
    isLoading: Boolean,
    indexMail: Number,
    mailIsOpen: Boolean,
    labels: Array,
    isCheckedALL: Boolean,
    activeEmail: Number,
    counter_click_folder: Number,
    lang: String,
    isCheckedAllFolder: Boolean,
    isMobile: Boolean,
  },
  data(){
    return {
      userAlias: ''
    }
  },
  computed: {
    letters(){
      const i = this.total > this.page*this.amount ? this.page*this.amount : this.total
      const j = (this.page-1)*this.amount + 1
      return j+'-'+i
    },
  },
  methods: {
    add_label(){
        this.$emit('add_label')
    },
    remove_all(){
      this.$emit("remove_all")
    },
    selectMails(flag){
      this.$emit('select_mails', flag)
    },
    move_to(email){
      this.$emit('move_to', email)
    },
    mark_as_read(flag, label_id){
      this.$emit('mark_as_read', flag, label_id)
    },
    refresh(){
      const route_name = this.$route.name
      if(route_name === 'search' || route_name === 'search_message'){
        const payload = {
          type: 'refresh',
          counter: Math.floor(Math.random() * 10000)
        }
        this.$store.dispatch('searchPagination', payload)
      }else{
        this.$emit("refresh")
      }
    },
    search_mail(values, type){
      this.$emit('search_mail', values, type)
    },
    returnToMailslist(){
      this.$emit("return_to_mailslist")
    },
    settings() {
      this.$router.push({name: "settings"});
    },
    contacts() {
      this.$router.push({name: "contacts", query: {tab: 0, limit: 100, page: 1, lang: this.lang}});
    },
    previous() {
      const route_name = this.$route.name
      const params = this.$route.params
      if((route_name == 'search' || route_name == 'search_message') && +params.page > 1)
        this.$router.push({name: route_name, params: {  page: params.page--, ...params}})
      else
        this.$emit("previous");
    },
    next() {
      const route_name = this.$route.name
      const params = this.$route.params
      if((route_name == 'search' || route_name == 'search_message') && +params.page < this.pages_amount)
        this.$router.push({name: route_name, params: {  page: params.page++, ...params}})
      else
        this.$emit("next");
    },
    previousLetter(){
      if(this.indexMail === 0)
        return
      this.$emit("previous_letter");
    },
    nextLetter(){
      if(this.indexMail === this.total)
        return
      this.$emit("next_letter");
    },
    delete_mail(){
      this.$emit('delete_mail')
    },
    about(){
      this.$router.push({name: 'about'})
    }
  },
  created() {
    this.userAlias = this.$store.getters['user'].Info?.alias
  }
};
</script>
<style scoped>
.unactive_arrow {
  color: rgb(197 188 188);
  cursor: unset;
  opacity: 0.4;
}
.top-panel__item--pages{
  white-space: nowrap;
}
</style>
